// import { provideHttpClient, withInterceptors } from '@angular/common/http';
// import { bootstrapApplication } from '@angular/platform-browser';
// import { provideRouter } from '@angular/router';
// import { AppComponent } from './app.component';
// import { customInterceptor } from './services/custom.interceptor';

// bootstrapApplication(AppComponent, {
//   providers: [
//     // routes
//     // provideRouter(routes),
//     // http client
//     provideHttpClient(
//       // registering interceptors
//       withInterceptors([customInterceptor])
//     ),
//   ],
// }).catch((err) => console.error(err));

import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { TablerIconsModule } from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
  HttpClient,
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { customInterceptor } from '../../../shared/src/lib/services/custom.interceptor';
import { ApiService } from '../../../shared/src/lib/services/api.service';
import { routes } from './app.routes';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideHttpClient(withInterceptors([customInterceptor])),
    importProvidersFrom(ApiService),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    importProvidersFrom(TablerIconsModule.pick(TablerIcons)),
    importProvidersFrom(FeatherModule.pick(allIcons)),
    provideAnimationsAsync('noop'),
    provideAnimationsAsync(),
  ],
};

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export interface AppSettings {
  dir: 'ltr' | 'rtl';
  theme: string;
  sidenavOpened: boolean;
  sidenavCollapsed: boolean;
  boxed: boolean;
  horizontal: boolean;
  activeTheme: string;
  language: string;
  cardBorder: boolean;
  navPos: 'side' | 'top';
}

export const defaults: AppSettings = {
  dir: 'ltr',
  theme: 'light',
  sidenavOpened: false,
  sidenavCollapsed: false,
  boxed: true,
  horizontal: true,
  cardBorder: false,
  activeTheme: 'orange_theme',
  language: 'en-us',
  navPos: 'side',
};
